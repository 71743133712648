import lozad from 'lozad'
import {header} from './scripts/header'
import swiperInit from './scripts/swiper'
import {home} from './scripts/home'
import { indicatorSlide, scrollAnimation, setBackgroundElement } from './scripts/helper'
$(document).ready(function () {
	scrollAnimation()
	header.init()
	home.init()
	swiperInit()
	setBackgroundElement()
	indicatorSlide()
	CountUpNumber()
})

function CountUpNumber() {
	var nodeCount = $('.countup')
	const options = {
		duration: 3,
		separator: '.',
		enableScrollSpy: true,
	}
	var nodeAnimation
	nodeCount.each(function (index) {
		var value = $(nodeCount[index]).data('number')
		nodeAnimation = new countUp.CountUp(nodeCount[index], value, options)
		nodeAnimation.start()
	})
}

/*==================== Lazyload JS ====================*/
const observer = lozad() // lazy loads elements with default selector as '.lozad'
observer.observe()
