
export function setBackgroundElement () {
	$('[setBackground]').each(function () {
		var background = $(this).attr('setBackground')
		$(this).css({
			'background-image': 'url(' + background + ')',
			'background-size': 'cover',
			'background-position': 'center center',
		})
	})
}
export function indicatorSlide() {
	if ($('.indicator-swipe').length > 0) {
		var callback = function (entries) {
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {
					entry.target.classList.add('active')
					setTimeout(function () {
						entry.target.classList.remove('active')
					}, 3000)
				}
			})
		}

		var observer = new IntersectionObserver(callback)
		var animationItems = document.querySelectorAll('.indicator-swipe')
		animationItems.forEach(function (item) {
			observer.observe(item)
		})
	}
}
export function detectCloseElement(ele, ele2, funcRemove) {
	// close
	$(document).on('click', function (e) {
		if (!$(e.target).closest(ele).length && !$(e.target).hasClass(ele2)) {
			funcRemove()
		}
	})
	// esc
	$(document).keyup(function (e) {
		if (e.key === 'Escape') {
			funcRemove()
		}
	})
	// overlay-blur
}
export function buttonToTop() {
	let windowHeight = $(window).height()
	$(document).on('scroll', function () {
		let scrollTop = $(window).scrollTop()
		let documentHeight = $(document).height()
		if (scrollTop + windowHeight > windowHeight + 400) {
			$('.button-to-top').addClass('active')
		} else {
			$('.button-to-top').removeClass('active')
		}
	})
	$(document).on('click', '.button-to-top', function () {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	})
}

export function ToggleItem() {
	const nodeParent = $('.wrap-item-toggle')
	const nodeItem = nodeParent.find('.item-toggle')
	const nodeTitle = nodeItem.find('.title')
	nodeTitle.on('click', function () {
		$(this)
			.toggleClass('active')
			.next()
			.slideToggle()
			.closest('.item-toggle')
			.siblings()
			.find('.title')
			.removeClass('active')
			.next()
			.slideUp()
	})
}

/**
 * parent, children, item, button, initItem
 * @param { parent, children, item, button, initItem} listNode
 */
export function funcExpandContent(listNode) {
	const {parent, children, item, button, initItem, gap = 0} = listNode
	if (!$(parent).length) return
	let itemHeight = $(item).outerHeight()
	let gapCalculate = gap
		? Number($(parent).find(children).css('column-gap').slice(0, -2)) * gap
		: 0
	let initHeight = itemHeight * initItem + gapCalculate
	let originalHeight = $(parent).find(children).outerHeight()
	if (originalHeight < initHeight) {
		$(button).remove()
	} else {
		$(parent).css('height', initHeight)
	}
	;``
	$(button).on('click', function () {
		if ($(this).hasClass('expand')) {
			$(parent).css('height', initHeight)
			$(this).find('span').text('Xem thêm')
		} else {
			$(parent).css('height', originalHeight)
			// setTimeout(() => {
			// 	$(parent).css("height", "auto");
			// }, 1000);
			$(this).find('span').text('Rút gọn')
		}
		$(this).toggleClass('expand')
	})
}

export function clickScrollToDiv(nodeEle, heightSpacing = () => {}) {
	$(nodeEle).on('click', function (event) {
		let height = 0
		$(this).addClass('active').siblings().removeClass('active')
		if (heightSpacing) {
			height = heightSpacing()
		} else {
			height = 0
		}
		if (this.hash !== '') {
			event.preventDefault()
			var hash = this.hash
			$('html, body').animate(
				{
					scrollTop: $(hash).offset().top - height,
				},
				800
			)
		}
	})
}

export function appendCaptchaASP() {
	if (!$('#ctl00_mainContent_ctl01_pnlFormWizard').length) return
	// Select the div element you want to observe
	const myDiv = document.querySelector(
		'#ctl00_mainContent_ctl01_pnlFormWizard'
	)
	// Create a new MutationObserver object
	const observer = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			console.log('Run')
			appendCaptcha()
		})
	})
	// Configure the observer to listen for changes to the "class" attribute
	const config = {attributes: true, characterData: true, childList: true}
	// Start observing the target div element
	observer.observe(myDiv, config)
	function appendCaptcha() {
		$('.form-group.frm-captcha').appendTo('.wrap-form-submit')
		$('.form-group.frm-btnwrap').appendTo('.wrap-form-submit')
	}
	appendCaptcha()
}
export function scrollAnimation() {
	if (
		$('.animate').length > 0 &&
		window.matchMedia('(min-width: 1200px)').matches
	) {
		var callback = function (e) {
				e.forEach(function (e) {
					e.isIntersecting &&
						(e.target.style.animation = e.target.dataset.animate)
				})
			},
			observer = new IntersectionObserver(callback, {}),
			animationItems = document.querySelectorAll('.animate')
		animationItems.forEach(function (e) {
			observer.observe(e)
		})
	}
}

export function scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed();
    console.log("🚀 ~ file: helper.js:183 ~ scrollTo ~ fixedOffset:", fixedOffset)
    const onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll)
                callback()
            }
        }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
        top: offset,
        behavior: 'smooth'
    })
}